import WeView from "../../component/basecomponent/WeView";
import { css, html, render } from "lit";

export default class FinishComponent extends WeView {


    static properties = {
        i18n: { type: Object },
        config: { type: Object },
        backgroundImage: { type: String },
    };

    constructor () {
        super();
        this.i18n = {};
    }


    backgroundImageLoader ( imtype ) {

        if (imtype === "lazy")
            return this.backgroundImage + "?w=50&blur=30";

        return this.backgroundImage;
    }

    restartProcess () {

        const event = new CustomEvent( "restart-process", {
            detail: true,
            bubbles: true, composed: true
        } );

        this.dispatchEvent( event );

    }

    renderRestartProces = ()=>
        html`
            <style>
                .reset-button {
                    width: 100%;
                }

                @media (min-width: 600px) {
                    .reset-button {
                        width: auto;
                        margin-top: 1em;
                    }
                }
            </style>
            <vaadin-vertical-layout theme="spacing padding" style="align-items: center; padding: 2em">
                <vaadin-button theme="primary large" @click="${ () => this.restartProcess() }" class="reset-button">
                    ${this.i18n.buttons?.processRestart}
                </vaadin-button>
            </vaadin-vertical-layout>
            
        `;



    render () {

        const mailSection = html`<span class="icon-text">
			<span class="icon">
				<i class="fa-solid fa-at"></i>
			</span>
			<span>
                <a href="mailto:${this.i18n.mail}"  class="white-space-pre-line">${this.i18n.mail}</a>
            </span>
		</span>`;

        const callSection = html`<span class="icon-text">
			<span class="icon">
				<i class="fa-solid fa-phone" ></i>
			</span>
			<span>
                <a href="tel:${this.i18n.phone}" class="is-info white-space-pre-line">${this.i18n.phone}</a>
            </span>
		</span>`;

        return html`
            <style>

                @media screen and (max-width: 460px) {
                    .card-box {
                        padding-top: 1em;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }

                    .card-content {

                    }

                    .on-mobile-fullwidth {
                        width: 100%;
                    }
                }

                .reset-button {
                    width: 100%;
                }

                
                
                @media screen and (min-width: 640px) {

                    .reset-button {
                        width: auto;
                        margin-top: 1em;
                    }
                    
                    .container {
                        max-width: 800px;
                    }

                    .card-box {
                        max-width: 640px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        padding-top: 1em;
                    }

                    .card-action {
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                    }

                    .spacer {
                        width: 100%;;
                    }
                }

                .card {
                    height: 100%;
                }

                .card-content > .content {
                    margin-top: 1.5em;
                }

                .hero-body {
                    background-color: #f3f4f5;
                }

                .hero-with-logo {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .hero-title {
                    min-width: 50%;
                }

                .logo-image {
                    display: flex;
                    min-width: 50%;
                    max-height: 5rem;;
                }

                .logo-image > img {
                    display: block;
                }

                .is-we-grey {
                    color: #7c7c7c;
                }

                #svg4 {
                    width: 100%;
                    background-color: white;
                }


                .card-action {
                    margin-top: auto;
                }


                .content > .title {
                    padding-bottom: 1.5em;;
                }

                .container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                }

                .wave-is-hidden {
                    display: none;
                }

                .content > .title {
                    padding-bottom: 1.5em;;
                }

                .hero-body-is-white {
                    background-color: white;
                }

                .white-space-pre-line  {
                    white-space: pre-line;
                }


            </style>
            <div class="container">
                <section class="hero is-small" style=" ${!this.config?.topVisible === true ? 'display: none' : ''}">
                    <div class="hero-body ${!this.config.waveVisible ? 'hero-body-is-white' : ''}">
                        <div class="hero-with-logo">
                            <div class="hero-title">
                                <p class="title white-space-pre-line">${this.i18n.heroTitle}</p>
                                <p class="subtitle white-space-pre-line">${this.i18n.heroSubTitle}</p>
                            </div>
                            <div class="logo-image">
                                ${this.config.logo && this.config.logoVisible === true ? html`<img src="${this.config.logo}"
                                                                               alt="log"/>` : ""}
                            </div>
                        </div>
                    </div>
                    <svg style="width: 100%;" class="${!this.config.waveVisible === true ? 'wave-is-hidden' : ''}"
                         viewBox="0 0 786.45166 71.022777"
                         version="1.1"
                         id="svg4"
                         sodipodi:docname="wave.svg"

                         inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
                         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:svg="http://www.w3.org/2000/svg">
                        <defs
                                id="defs8"/>
                        <sodipodi:namedview
                                id="namedview6"
                                pagecolor="#ffffff"
                                bordercolor="#666666"
                                borderopacity="1.0"
                                inkscape:pageshadow="2"
                                inkscape:pageopacity="0.0"
                                inkscape:pagecheckerboard="0"
                                showgrid="false"
                                inkscape:zoom="0.78785519"
                                inkscape:cx="531.18898"
                                inkscape:cy="8.8848815"
                                inkscape:window-width="1920"
                                inkscape:window-height="1127"
                                inkscape:window-x="0"
                                inkscape:window-y="0"
                                inkscape:window-maximized="1"
                                inkscape:current-layer="svg4"
                        />
                        <path
                                fill="#f3f4f5"
                                fill-opacity="1"
                                d="M 0,25.763344 43.691761,35.773477 C 87.383519,45.890956 174.76705,65.750204 262.15056,70.124601 349.53409,74.33798 436.91761,63.066519 524.30114,50.104337 611.68466,37.303175 699.06818,22.811294 742.75994,15.753211 L 786.45168,8.5877811 V 0 H 742.75994 C 699.06818,0 611.68466,0 524.30114,0 436.91761,0 349.53409,0 262.15056,0 174.76705,0 87.383519,0 43.691761,0 H 0 Z"
                                id="path2"
                                style="stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"/>
                    </svg>
                </section>

                <div class="card-box">
                    <div class="card-content">
                        <div style="text-align: center; padding: 1em;">
                            <svg width="5rem" height="5rem" viewBox="0 0 48 48" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
                                      fill="#37748F"/>
                                <path d="M19 20C19 21.1046 18.1046 22 17 22C15.8954 22 15 21.1046 15 20C15 18.8954 15.8954 18 17 18C18.1046 18 19 18.8954 19 20Z"
                                      fill="#37748F"/>
                                <path d="M33 20C33 21.1046 32.1046 22 31 22C29.8954 22 29 21.1046 29 20C29 18.8954 29.8954 18 31 18C32.1046 18 33 18.8954 33 20Z"
                                      fill="#37748F"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M29.4327 30.1765L29.4308 30.1777L29.4128 30.1899C29.3954 30.2014 29.3676 30.2197 29.3301 30.2437C29.2549 30.2918 29.1408 30.3629 28.9931 30.4493C28.697 30.6225 28.2693 30.8552 27.7515 31.0878C26.7013 31.5597 25.3491 32 24 32C22.6509 32 21.2987 31.5597 20.2485 31.0878C19.7307 30.8552 19.303 30.6225 19.0069 30.4493C18.8592 30.3629 18.7451 30.2918 18.6699 30.2437C18.6324 30.2197 18.6046 30.2014 18.5872 30.1899L18.5692 30.1777L18.5673 30.1765C18.5674 30.1766 18.5672 30.1764 18.5673 30.1765M18.5673 30.1765C18.5671 30.1763 18.5664 30.1759 18.5662 30.1757C18.1115 29.8634 17.4896 29.9783 17.1765 30.4327C16.8632 30.8875 16.9779 31.5102 17.4327 31.8235L17.9667 31.0484C17.4327 31.8235 17.4325 31.8234 17.4327 31.8235L17.4348 31.825L17.4381 31.8272L17.4477 31.8337L17.4795 31.8551C17.5062 31.8728 17.5438 31.8975 17.5917 31.9282C17.6874 31.9894 17.8243 32.0746 17.9971 32.1757C18.3421 32.3775 18.8338 32.6448 19.4289 32.9122C20.6045 33.4403 22.2524 34 24 34C25.7476 34 27.3955 33.4403 28.5711 32.9122C29.1662 32.6448 29.6579 32.3775 30.0029 32.1757C30.1757 32.0746 30.3126 31.9894 30.4083 31.9282C30.4562 31.8975 30.4938 31.8728 30.5205 31.8551L30.5523 31.8337L30.5619 31.8272L30.5652 31.825L30.5664 31.8242C30.5666 31.824 30.5673 31.8235 30 31L30.5673 31.8235C31.0221 31.5102 31.1368 30.8875 30.8235 30.4327C30.5103 29.978 29.8875 29.8635 29.4327 30.1765"
                                      fill="#37748F"/>
                            </svg>
                        </div>

                        <div class="content">
                            <p class="title is-5 is-we-gray white-space-pre-line">${this.i18n.title}</p>
                            <p class="white-space-pre-line">${this.i18n.finishText}</p>
                            <p>${this.i18n?.mail?.length > 0 ? mailSection : ""}</p>
                            <p>${this.i18n?.phone?.length > 0 ? callSection : ""}</p>
                        </div>
                    </div>
                </div>
                ${this.config.restartProcess ? this.renderRestartProces():"" }
            </div>

        `;
    }
}

window.customElements.define("finish-component", FinishComponent);
