import { html, LitElement } from "lit";

export default class FinishViewComponent extends LitElement {
	static properties = {
		i18n: { type: Object },
	};

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.i18n = {
			finishTitle: "Ready",
			finishSubTitle: "",
			finishText: "Your data was send properly!",
		};
	}

	render() {
		return html`
			<section class="hero is-fullheight">
				<div class="hero-body">
					<div class="container has-text-centered is-align-content-center ">
						<p class="title has-text-white">${this.i18n.finishTitle}</p>
						<p class="subtitle has-text-white">${this.i18n.finishSubTitle}</p>
						<section class="has-text-centered">
							<p class="subtitle has-text-white">${this.i18n.finishText}</p>
						</section>
					</div>
				</div>
			</section>
		`;
	}
}

window.customElements.define("finish-view", FinishViewComponent);
