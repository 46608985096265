/*jshint esversion: 11 */
import { elem, getConfigForComponent, taskId, TOKEN_UUID, validateToken } from "../../config";
import localforage from "localforage";

import "./FinishViewComponent";
import "./FinishComponent";
import * as localForage from "localforage";

const prepareComponent = ( { configuration, result } ) => {

    const {
        translates,
        backgroundImage,
        component,
        topVisible = true,
        logoVisible = true,
        waveVisible = true
    } = configuration;


    const finishView = document.createElement( component );

    finishView.i18n = translates;
    finishView.backgroundImage = backgroundImage;
    finishView.config = {
        restartProcess: result.config.withAuthorization !== undefined,
        topVisible: topVisible,
        logo: result.config?.logoUrl,
        logoVisible: logoVisible,
        waveVisible: waveVisible
    };


    elem.innerHTML = "";
    elem.appendChild( finishView );

    return finishView;
}

const rmoveTrash = () => {

    let deleteRequest = indexedDB.deleteDatabase("datOffline");

    deleteRequest.onerror = function(event) {
        console.log("Delete database datOffline error:", event);
    };

    deleteRequest.onsuccess = function(event) {
        console.log("Success database was removed:", event);
    };


}

/**
 * Finish View Page
 * @constructor
 * @param configuration
 */
export default function FinishView ( configuration ) {
    window.scrollTo( 0, 0 );

    window.onbeforeunload = null;

    return new Promise( async ( resolve ) => {

        const result = await localforage.getItem( taskId );

        const finishView = prepareComponent( { configuration, result } );

        document.addEventListener( "restart-process", async ( data ) => {

            await localforage.removeItem( taskId );

            rmoveTrash();

            if ( !(await validateToken()) ) {
                await localforage.removeItem( taskId + TOKEN_UUID );

            }

            location.reload();
        } );


        result.status = "sent";
        delete (result.steps)
        delete (result.model)
        result.wedat.views = [ result.wedat.views.find( item => item.component === 'finish-component' ) ];

        await localforage.setItem( taskId, result );

    } );
}
